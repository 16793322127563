export default {
    extractVariable(content) { 
        return content.match( /{{([^{}]+)}}/g ); 
    },
    isNumeric(value) {
        return /^-?\d*\.?\d*$/.test(value);
    },
    runCustomValidation(validation_script, answer_data){
        var variables = this.extractVariable(validation_script);
        if (variables!= null){
            var variable_values = [];
            for(let i = 0; i < variables.length ; i++){
              var question_id = variables[i].replace("{{variable-", "").replace("}}", "");
              for (let j = 0; j < answer_data.length; j++) {
                if (answer_data[j].question_id == question_id) {
                  variable_values.push({'name': variables[i], 'value': answer_data[j].value});
                }
              }
            }
            var validation_string = JSON.parse(JSON.stringify(validation_script))
            if (variable_values.length == variables.length){
                for (let k = 0; k < variable_values.length; k++) {
                    // if (this.isNumeric(variable_values[k].value)){
                    //     validation_string = validation_string.replace(variable_values[k].name, variable_values[k].value)
                    // }else{
                    //     validation_string = validation_string.replace(variable_values[k].name, "'" + variable_values[k].value + "'")
                    // }
                    validation_string = validation_string.replace(variable_values[k].name, variable_values[k].value)
                }
                return eval(validation_string);
            }else{
                // error
                return true;
            }
        }else{
            return eval(validation_script);
        }
    }
}
