<template>
<v-container>
  <v-row no-gutters v-if="is_required == false">
    <v-col 
          cols="auto"
          class="ml-auto mb-1">
      <v-btn text small @click="skip" :style="{color: bot_setting.main_color}">Skip »</v-btn>
    </v-col>
  </v-row>
  <v-row no-gutters justify="center" align="center">
    <v-col 
      cols="auto"
      class="ml-auto mb-1">
      <v-btn 
        class="ml-2 mb-2"
        rounded dark outlined
        :style="{color: bot_setting.main_color}"

        v-for="(item, index) in selection_options" v-bind:key="index">
        <v-checkbox
            light
            class="my-auto custom-checkbox mt-1"
             
            @change="userSelectedValue($event, index)" 
            :style="{color: bot_setting.main_color}"
            :color="bot_setting.main_color" 
            :label="item.title"
        ></v-checkbox>
      </v-btn>
    </v-col>
  </v-row>
  <v-row no-gutters justify="center" align="center">
    <p v-if="enableSubmit == false" style="color:#ff5252 !important; font-size: 12px;">{{error_message_2}}</p>
    <!--<p v-else style="color:rgba(0, 0, 0, 0.6) !important; font-size: 12px;">{{placeholder}}</p>-->
  </v-row>
  <div>
    <v-row no-gutters v-for="(item, index) in confirmation.items" v-bind:key="index">
      <v-col cols="auto">
        <v-checkbox
            v-model="item.default"
            class="mt-0 confirmation-checkbox"
            :color="bot_setting.main_color"
          >
        </v-checkbox>
      </v-col>
      <v-col class="mt-1">
          <span style="white-space: pre-wrap;" v-html="item.value"></span>
      </v-col>
    </v-row>
    <div class="my-2 red--text darken-1 confirmation-message" v-if="confirmationValid == false">{{confirmation.error_message}}</div>
  </div>
  <v-row no-gutters justify="center" align="center">
    <v-btn elevation="3" dark @click="submit" :style="{backgroundColor: bot_setting.main_color}">Submit</v-btn>
  </v-row>
</v-container>
</template>

<style>
.custom-checkbox .v-messages{
  display:none;
}
</style>


<script>
import utils from '@/utils/utils.js'

export default {

  name: "MultiSelectionInput",

  props: ['placeholder', 'error_message', 'bot_setting', 'is_required', 'selection_options', 'confirmation', 'label_name', 'question_id',  'answer_data', 'custom_validation'],

  created(){
    this.selectedData = this.selection_options
    this.error_message_2 = this.error_message
  },

  data: () =>({
    selectedData:[],
    enableSubmit: true,
    confirmationValid: true,
    error_message_2: ''
  }),


  methods: {
    skip(){
      this.$emit('skip_input_submitted')
    },

    userSelectedValue(event, index){
      this.enableSubmit = true
      if (event == true){
        this.selectedData[index].selected = true
      }else{
        this.selectedData[index].selected = false
      }
    },

    submit(){
      this.error_message_2 = this.error_message
      var emitValue = {user_chat_message: '', user_chat_value:''}

      var checkedItem = false

      for(var i=0; i< this.selectedData.length; i++){
        var item = this.selectedData[i]
        if (item.selected == true){
          emitValue.user_chat_message += item.title + ','
          emitValue.user_chat_value += item.value + ','

          checkedItem = true
        }
      }

      if (checkedItem == false){
        this.enableSubmit = false
        this.confirmationValid = true
        this.$emit('calculate_padding')
        return false
      }

      if (checkedItem == true && emitValue.user_chat_message.length > 1)
        emitValue.user_chat_message = emitValue.user_chat_message.substring(0, emitValue.user_chat_message.length - 1);

      if (checkedItem == true && emitValue.user_chat_value.length > 1)
        emitValue.user_chat_value = emitValue.user_chat_value.substring(0, emitValue.user_chat_value.length - 1);

      //console.log(emitValue.user_chat_value)
      if (this.customValidation(emitValue.user_chat_value) == false) {
        this.enableSubmit = false
        this.confirmationValid = true
        this.$emit('calculate_padding')
        return false
      }else{
        if (this.validateConfirmation() == true) {
          this.$emit('multi_slection_input_submitted', emitValue)
        } 
      }
    },

    customValidation(_input_value){
      if (this.custom_validation.length > 0){
        this.error_message_2 = ''
        var temp_answer_data = JSON.parse(JSON.stringify(this.answer_data))
        temp_answer_data.push({ 'key': this.label_name, 'value': _input_value, 'question_id': this.question_id })

        for(var j=0; j < this.custom_validation.length; j++){
          if (this.custom_validation[j].rule != ''){
            var validation_result = utils.runCustomValidation(this.custom_validation[j].rule, temp_answer_data)
            if (validation_result == false){
              this.error_message_2 = this.custom_validation[j].error_message
              return false
            }
          }
        }
      }
      return true
    },

    validateConfirmation(){
      this.confirmationValid = true
      for(var i=0; i < this.confirmation.items.length; i++){
        if (this.confirmation.items[i].default == false){
          this.confirmationValid = false
        }
      }
      this.$emit('calculate_padding')
      if (this.confirmationValid == true) {
        return true
      }
      return false
    }
  }

}
</script>