<template>
<v-container>
  <v-row no-gutters v-if="is_required == false">
    <v-col 
          cols="auto"
          class="ml-auto mb-1">
      <v-btn text small @click="skip" :style="{color:  bot_setting.main_color}">Skip »</v-btn>
    </v-col>
  </v-row>
  <v-row no-gutters justify="center" align="center">
    <v-col class="pr-2">
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="input_value"
        max-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            ref="input_value"
            v-model="input_value"
            :label="placeholder"
            :error-messages="!checkValid ? error_message_2: ''"
            readonly
            solo
            v-bind="attrs"
            v-on="on"
            :type="input_type"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="input_value"
          no-title
          scrollable
          locale="ja-jp"
          :day-format="day_format"
          @input="$refs.dialog.save(input_value)"
        >
        </v-date-picker>
      </v-dialog>

      <div>
        <v-row no-gutters v-for="(item, index) in confirmation.items" v-bind:key="index">
          <v-col cols="auto">
            <v-checkbox
                v-model="item.default"
                class="mt-0 confirmation-checkbox"
                :color="bot_setting.main_color"
              >
            </v-checkbox>
          </v-col>
          <v-col class="mt-1">
              <span style="white-space: pre-wrap;" v-html="item.value"></span>
          </v-col>
        </v-row>
        <div class="my-2 red--text darken-1 confirmation-message" v-if="confirmationValid == false">{{confirmation.error_message}}</div>
      </div>
    </v-col>
    <v-col 
          cols="auto"
          class="ml-auto mb-auto">
      <v-btn class="margin-top-0375" elevation="3" dark @click="submit" :style="{backgroundColor: bot_setting.main_color}">Submit</v-btn>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import utils from '@/utils/utils.js'

export default {

  name: "DateFieldInput",

  props: ['placeholder', 'error_message', 'pattern_match', 'bot_setting', 'is_required', 'answer_type', 'confirmation', 'label_name', 'question_id', 'answer_data', 'custom_validation'],

  data: () =>({
    input_value: "", 
    checkValid: true,
    input_type:'text',
    confirmationValid: true,
    modal: false,
    menu: false,
    error_message_2: ''
  }),

  created(){ 
    this.error_message_2 = this.error_message
  },


  methods: {
    skip(){
      this.$emit('skip_input_submitted')
    },
    submit(){
      if (this.validateInput() == true){
        this.checkValid = true
        if (this.validateConfirmation() == true) {
          var emitValue = {user_chat_message: this.input_value, user_chat_value:this.input_value}
          this.$emit('text_input_submitted', emitValue)
          this.input_value = ''
        }
      }else{
        this.confirmationValid = true
        this.checkValid = false
        this.$emit('calculate_padding')
        this.$nextTick(()=>this.$refs.input_value.focus())
      }
    },

    validateConfirmation(){
      this.confirmationValid = true
      for(var i=0; i < this.confirmation.items.length; i++){
        if (this.confirmation.items[i].default == false){
          this.confirmationValid = false
        }
      }
      this.$emit('calculate_padding')
      if (this.confirmationValid == true) {
        return true
      }
      return false
    },

    customValidation(_input_value){
      if (this.custom_validation.length > 0){
        this.error_message_2 = ''
        var temp_answer_data = JSON.parse(JSON.stringify(this.answer_data))
        temp_answer_data.push({ 'key': this.label_name, 'value': _input_value, 'question_id': this.question_id })

        for(var j=0; j < this.custom_validation.length; j++){
          if (this.custom_validation[j].rule != ''){
            var validation_result = utils.runCustomValidation(this.custom_validation[j].rule, temp_answer_data)
            if (validation_result == false){
              this.error_message_2 = this.custom_validation[j].error_message
              return false
            }
          }
        }
      }
      return true
    },

    validateInput(){
      this.error_message_2 = this.error_message
      this.checkValid = true
      if (this.is_required == true){
        if (this.input_value == null || this.input_value == ""){
          return false
        }
      }
      if (this.pattern_match != null || this.pattern_match != ""){
        var regex = new RegExp(this.pattern_match)
        if (regex.test(this.input_value)){
          if (this.customValidation(this.input_value) == false) {
            this.checkValid = false
            return false
          }else{
            return true
          }
        }else{
          this.checkValid = false
          return false
        }
      }else{
        return true
      }
    },
    day_format(date){
      return new Date(date).getDate()
    }
  }

}
</script>