<template>
<v-container>
  <v-row no-gutters v-if="is_required == false">
    <v-col 
          cols="auto"
          class="ml-auto mb-1">
      <v-btn text small @click="skip" :style="{color: bot_setting.main_color}">Skip »</v-btn>
    </v-col>
  </v-row>
  <v-row no-gutters justify="center" align="center">
    <v-col 
      lg="6"
      md="6"
      sm="12"
      xs="12"
      class="ml-auto mr-auto">
      <v-row>
        <v-select class="pr-2"
            v-model="select"
            :items="selection_options"
            item-text="title"
            item-value="value"
            :label="placeholder"
            :error-messages="!checkValid ? error_message_2: ''"
            return-object
            solo
        ></v-select>
        <!--<v-btn class="mt-1" dark @click="submit" :style="{backgroundColor: bot_setting.main_color}">Submit</v-btn>-->
    </v-row>
    </v-col>
  </v-row>
  <v-row no-gutters justify="center" align="center">
    <v-col 
      lg="6"
      md="6"
      sm="12"
      xs="12"
      class="ml-auto mr-auto">
      <v-row no-gutters v-for="(item, index) in confirmation.items" v-bind:key="index">
        <v-col cols="auto">
          <v-checkbox
              v-model="item.default"
              class="mt-0 confirmation-checkbox"
              :color="bot_setting.main_color"
            >
          </v-checkbox>
        </v-col>
        <v-col class="mt-1">
            <span style="white-space: pre-wrap;" v-html="item.value"></span>
        </v-col>
      </v-row>
      <div class="my-2 red--text darken-1 confirmation-message" v-if="confirmationValid == false">{{confirmation.error_message}}</div>
    </v-col>
  </v-row>
  <v-row no-gutters justify="center" align="center">
    <v-btn elevation="3" dark @click="submit" :style="{backgroundColor: bot_setting.main_color}">Submit</v-btn>
  </v-row>
</v-container>
</template>

<script>
import utils from '@/utils/utils.js'

export default {

  name: "DropdownInput",

  props: ['placeholder', 'error_message', 'bot_setting', 'is_required', 'selection_options', 'confirmation', 'label_name', 'question_id', 'answer_data', 'custom_validation'],

  created(){
    this.checkValid = true
    this.error_message_2 = this.error_message
  },

  data: () =>({
    select: null,
    options:[],
    checkValid: true,
    confirmationValid: true,
    error_message_2: ''
  }),


  methods: {
    skip(){
      this.$emit('skip_input_submitted')
    },

    submit(){ 
      if (this.validateInput() == true){
        if (this.validateConfirmation() == true) {
            var emitValue = {user_chat_message: this.select.title, user_chat_value:this.select.value}
            this.$emit('dropdown_input_submitted', emitValue)
          }
      }
    },

    customValidation(_input_value){
      if (this.custom_validation.length > 0){
        this.error_message_2 = ''
        var temp_answer_data = JSON.parse(JSON.stringify(this.answer_data))
        temp_answer_data.push({ 'key': this.label_name, 'value': _input_value, 'question_id': this.question_id })

        for(var j=0; j < this.custom_validation.length; j++){
          if (this.custom_validation[j].rule != ''){
            var validation_result = utils.runCustomValidation(this.custom_validation[j].rule, temp_answer_data)
            if (validation_result == false){
              this.error_message_2 = this.custom_validation[j].error_message
              return false
            }
          }
        }
      }
      return true
    },

    validateConfirmation(){
      this.confirmationValid = true
      for(var i=0; i < this.confirmation.items.length; i++){
        if (this.confirmation.items[i].default == false){
          this.confirmationValid = false
        }
      }
      this.$emit('calculate_padding')
      if (this.confirmationValid == true) {
        return true
      }
      return false
    },

    validateInput(){
      this.error_message_2 = this.error_message
      this.checkValid = true
      if (this.select != null){
        if (this.customValidation(this.select.value) == false) {
          this.checkValid = false
          return false
        }else{
          return true
        }
      }else{
        this.checkValid = false
        return false
      }
    }
  },
  watch:{
    // select: function(){
    //   this.checkValid = true
    // }
  }
}
</script>