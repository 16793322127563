import axios from 'axios'
import router from '@/router'
import Cookies from 'js-cookie'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true,
  timeout: 5000, // request timeout
  //crossDomain: true
  headers: { 'Cache-Control': 'no-cache' }
})

// request interceptor
service.interceptors.request.use(
)

service.interceptors.response.use(response => {
  return response 
}, error => {
	var error_message = 'Error: '
	if (error.response &&  error.response.status && error.response.status == 401) {
		var chat_id = window.location.pathname
		chat_id = chat_id.substring(1)
		var url_string = window.location.href
		var url = new URL(url_string)
		var bot_id = url.searchParams.get("bot_id")
		if (bot_id != null){
			Cookies.remove( chat_id + bot_id )
		}else{
			Cookies.remove(chat_id)
		}
		error_message += error.response.status + ' - ' + error.response.data.mess
		router.go(0)
	}else if (error.request) {
		error_message += error.message
	}else{
		error_message += error.message
	}
	return Promise.reject(error_message)
});

export default service
